
import { defineComponent, ref, watch, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { updateUser, getUserRole, getCustomersList } from "@/store/api/devices";
import { hideModal } from "@/core/helpers/dom";
export default defineComponent({
  name: "AddUser",
  props: {
    userRec: { type: Object, required: true },
  },

  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const roles = ref<any[]>([]);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    let user = ref<any>([]);
    const customers = ref<any[]>([]);
    const errors = ref<any[]>([]);
    watch(
      () => props.userRec,
      (v) => {
        user.value = v;
      }
    );
    onMounted(async () => {
      getCustomersList().then((response) => {
        customers.value = response;
      });
      getUserRole().then((response) => {
        roles.value = response.data;
      });
    });
    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      updateUser(user.value.id, user.value)
        .then((res) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          document.getElementById("editnewmodalclose")?.click();
          Swal.fire({
            icon: "success",
            title: "User has been Updated",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          errors.value = e.response.data.message;
          //   if (submitButtonRef.value) submitButtonRef.value.disabled = false;
          //   submitButtonRef.value?.removeAttribute("data-kt-indicator");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${errors.value}`,
          });
        });
    };

    return {
      user,
      roles,
      submit,
      customers,
      submitButtonRef,
      createAPIKeyModalRef,
      modalRef,
    };
  },
});
