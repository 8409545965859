
import AddUser from "./modal/AddUser.vue";
import EditUserProfile from "./modal/EditUserProfile.vue";
import ChangePassword from "./modal/ChangePassword.vue";
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getCustomersList,
  getUsersList,
  deleteUser,
} from "@/store/api/devices";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    AddUser,
    ChangePassword,
    EditUserProfile,
  },
  setup() {
    const data = ref<any[]>([]);
    const userdata = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const UserId = ref<number>();
    const userDataforUpdate = ref<any[]>([]);
    const headerConfig = ref([
      {
        name: "ID",
        key: "id",
      },
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Email",
        key: "email",
      },
      {
        name: "Phone Number",
        key: "phone_number",
      },
      {
        name: "Address",
        key: "address",
      },
      {
        name: "Company Name",
        key: "se_account_id",
      },
      {
        name: "Action",
        key: "diagnostic_uplink",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const permissions = ref<any>("");

    onMounted(async () => {
      loading.value = true;
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      getCustomersList().then((response) => {
        customers.value = response;
        loadData();
      });
      setCurrentPageBreadcrumbs("Users", [""]);
    });
    const loadData = async () => {
      loading.value = true;
      let userlist = await getUsersList(pagination.value);
      userdata.value = userlist.data.users;
      total.value = userlist.data.total_number_of_records;
      loading.value = false;
    };
    const delteUser = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this user?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteUser(id);
          console.log(resp);
          loadData();
        }
      });
    };

    const getUserId = (val) => {
      UserId.value = val;
    };
    const getUserDetail = (val) => {
      userDataforUpdate.value = val;
    };
    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };
    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    return {
      data,
      userDataforUpdate,
      UserId,
      getUserDetail,
      delteUser,
      userdata,
      headerConfig,
      customers,
      total,
      loading,
      getUserId,
      search,
      pagination,
      cardData,
      currentChange,
      searchItems,
      itemsPerPageChange,
      onCustomerChange,
      permissions,
    };
  },
});
